import React from 'react';

const Logo = ({ title, ...props }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={92}
    height={112}
    {...props}
  >
    <title>{title}</title>
    <defs>
      <path id="a" d="M.247.099h22.438v23.368H.247z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#DD1F27" d="M0 0v112h68.645L91 88.794V0z" />
      <path
        fill="#FFF"
        d="M47.742 14.003l-8.274 26.652h-2.615l-6.601-21.25-6.645 21.25h-2.615L11.99 12.931c-.257-.759-.515-1.205-.986-1.696L9.16 9.359V9.27h10.675v.09l-3.044 2.41c-.3.224-.386.492-.171 1.072l7.544 23.215 5.659-18.036-1.586-5.09c-.257-.759-.515-1.205-.986-1.696l-1.586-1.876V9.27h10.46v.09l-3.044 2.41c-.3.224-.386.447-.214 1.027l7.201 23.215 6.807-22.006.867-.003"
      />
      <path
        fill="#FFF"
        d="M64.583 9.412l.041-.018H40.533l.04.018h-.038v7.07l4.777-5.83c.28-.291.659-.455 1.055-.455h3.982v27.501c0 .491-.085.625-.557.893l-3.901 2.098v.09h13.375v-.09l-3.901-2.098c-.514-.268-.558-.402-.558-.893v-27.5h4.261c.224 0 .438.092.596.256l4.967 6.028v-7.07h-.048z"
      />
      <g transform="translate(68.8 88.427)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path fill="#870B0B" mask="url(#b)" d="M22.685.099H.247v23.368" />
      </g>
    </g>
  </svg>
);

export default Logo;
